<template>
  <div class="Account">
    <transition
        enterActiveClass="animate__animated animate__fadeIn"
        leaveActiveClass="animate__animated animate__fadeOut"
    >
      <div v-if="confirmIt" class="Confirm">
        <div class="Confirm__box">
          <h2>Hai salvato la tua chiave privata?</h2>
          <p>Senza di essa non potrai accedere al tuo account EOS!</p>
          <p>Se non lo hai fatto clicca su copia e salvala in un posto sicuro prima di procedere.</p>
          <div class="Confirm__box__buttons">
            <button @click="copy()">Copia</button>
            <button class="secondary" @click="confirmIt=false">Annulla</button>
            <button @click="submit">Procedi</button>
          </div>
        </div>
      </div>
    </transition>
    <div class="Account__box" v-if="!keys">
      <p class="instructions">Il nome del tuo account EOS deve essere di 12 caratteri, deve cominciare con una lettera e può contenere numeri dall' 1 al 5.<br>Sono permessi solo caratteri minuscoli</p>
      <div class="InputContainer">
        <label for="username">Account EOS</label>
        <input
            ref="username"
            id="username"
            type="text"
            v-model="username"
            maxlength="12"
        />
        <div class="error" v-if="invalidUsername.length">
          <ul>
            <li>
              Il nome del tuo account EOS:
            </li>
            <li v-for="(e, idx) in invalidUsername" :key="idx">
              {{ e }}
            </li>
          </ul>
        </div>
        <div class="error" v-if="usernameError">
          {{ usernameError }}
        </div>
        <div class="message" v-if="usernameMessage">
          {{ usernameMessage }}
        </div>
      </div>
      <button
          @click="onBtnVerifyAccount()"
          :disabled="!usernameValid"
      >
        Genera
      </button>
    </div>
    <div class="Account__box" v-if="keys">
      <button
          @click="backToName()"
          class="back"
      >
        Indietro
      </button>
      <p class="alert">
        Conserva con attenzione la tua chiave privata, senza di essa non potrai accedere MAI PIÙ al tuo account<br/><br/>
        Per motivi di privacy e sicurezza A-LIVE non può conservare la tua chiave privata
      </p>
      <div class="InputContainer">
        <label for="username">Account EOS</label>
        <input
            readonly
            type="text"
            :value="username"
        />
      </div>
      <div class="InputContainer">
        <label for="username">Chiave Privata</label>
        <div class="inputton">
          <input
              @focus="$event.target.select()"
              ref="clone"
              id="privateKey"
              type="text"
              v-model="privateKey"
              readonly
          />
          <button
              class="bottom"
              @click="copy"
          >
            Copia
          </button>
        </div>
        <button
            class="bottom"
            @click="confirmIt = true"
        >
          Invia la tua chiave pubblica
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import get from 'lodash.get';
import has from 'lodash.has';
import axios from 'axios';
//const RX = '^[a-z][a-z1-5]{0,10}([a-z1-5]|^.)[a-j1-5]?$';
const RX = '^[a-z][a-z1-5]{11}$';
const JsonRpc = window.eosjs_jsonrpc.JsonRpc
const Keygen = window.kos.Keygen;
const RPC_URLS = {
  'mainnet': 'https://eos.greymass.com',
  'testnet': 'https://api.testnet.eos.io',
  'jungle': 'https://jungle3.cryptolions.io:443'
};

function initRpc(network) {
  network = network.toLowerCase();
  if (!(network in RPC_URLS)) {
    throw new Error('Cannot find network configuration for network: ' + network);
  }
  return new JsonRpc(RPC_URLS[network]);
}

class EosAccountUtils {

  constructor(network = 'mainnet') {
    this.network = network;
    this.rpc = initRpc(network)
  }

  /**
   *
   * @param {string} [masterPrivateKey = null] When null, a new random key is created.
   * @returns {Promise<object>} masterKeys
   * @example
   *    masterKeys = {
   *       masterPrivateKey, // <= place in a password input field (password manager)
   *       privateKeys: {owner, active}, // <= derived from masterPrivateKey
   *       publicKeys: {owner, active} // <= derived from masterPrivateKey
   *    }
   */
  generateKeys(masterPrivateKey = null) {
    return Keygen.generateMasterKeys(masterPrivateKey);
  }

  /**
   * Retrieves account information for a given name
   * @param {string} accountName the name of the account to retrieve
   * @returns {Promise<GetAccountResult>} the account information, or `null` if not found
   */
  getAccount(accountName) {
    return this.rpc.get_account(accountName);
  }
}

export default {
  name: "AccoutEOS",
  data() {
    return {
      username: '',
      usernameError: '',
      usernameMessage: '',
      privateKey: '',
      publicKey: '',
      JsonRpc: false,
      keys: false,
      confirmIt: false,
    }
  },
  mounted() {
    const that = this;
    let status = this.$cookies.get(`${window.TOK_NS}storageAccessData`);
    if (!status || status == null) {
      status = this.$ls.get('storageAccessData', false);
    }
    if (status && status != null) {
      this.setLoginInfo(status);
      if (!this.me) {
        this.getMe()
            .then(() => {
              that.handleStatus();
            })
            .catch((error) => {
              window.app.$log.error(error);
            });
      } else {
        this.handleStatus();
      }
    } else {
      this.sendToRegistration();
    }
  },
  methods: {
    ...mapActions(['getMe']),
    ...mapMutations(['appReady', 'redirectToIP', 'setLoginInfo']),
    handleStatus() {
      if (!get(this, 'me.registered')) {
        this.sendToRegistration();
      } else {
        this.appReady(true);
      }
    },
    sendToRegistration() {
      this.redirectToIP({
        router: this.$router,
        registered: true,
        event: 'eos',
      });
    },
    async onBtnVerifyAccount() {
      const acctVerifyRegex = new RegExp(RX, 'g');
      try {
        const name = this.username;
        const network = 'mainnet';
        const acctUtils = new EosAccountUtils(network);
        const m = acctVerifyRegex.test(name);
        if (!m) {
          throw new Error('Invalid account name: ' + name);
        }

        try {
          const acct = await acctUtils.getAccount(name);
          console.log('Got account!', acct);
          this.usernameMessage = '';
          this.usernameError = 'Questo nome non è disponibile.';
          this.keys = false;
        } catch (err) {
          this.usernameError = '';
          this.keys = true;
          this.onBtnGenerateKeys();
        }


      } catch (err) {
        alert('Could not verify EOS account: ' + err);
      }

    },
    async onBtnGenerateKeys() {
      try {
        const acctUtils = new EosAccountUtils('mainnet');
        const keys = await acctUtils.generateKeys();
        this.privateKey = keys.privateKeys.owner;
        this.publicKey = keys.publicKeys.owner;
      } catch (err) {
        alert('Coult not generate EOS Keys: ' + err);
      }
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
    },
    backToName() {
      this.keys = false;
      this.privateKey = '';
      this.publicKey = '';
    },
    submit() {
      const self = this;
      const token = get(this.loginInfo, 'token');
      const c = this.conf[this.env];
      const baseUrl = get(c, 'usersApi.url');
      // const baseUrl = 'https://acceptance.tok.tv:8089/users/v1/';
      // const baseUrl = 'https://usersapi.tok.tv/v1/';
      axios.post(`${baseUrl}accounts`,
          {
            name: this.username,
            publicKey: this.publicKey,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'TOK-APP-ID': c.appid,
            },
          }).then((res) => {
        console.log(res);
        alert('La tua richiesta è stata completata.');
        this.publicKey = '';
        this.privateKey = '';
        this.username = '';
        this.keys = false;
        self.confirmIt = false;
        self.$router.push('/home');
      })
          .catch((e) => {
            const error = get(e, 'response.data');
            // const msg = get(error, 'message');
            const errors = get(error, 'errors');
            if (has(errors, 'account')) {
              alert(`Attenzione!\nIl tuo account A-LIVE ha già associato un account EOS`);
            }
            if (has(errors, 'name')) {
              alert(`Attenzione!\nQuesto account EOS è già associato ad un altro account, scegli un altro nome.`);
            }
            self.publicKey = '';
            self.privateKey = '';
            self.username = '';
            self.keys = false;
            setTimeout(() => {
              self.$router.push('/home');
            }, 3000);
          });
    },
  },
  computed: {
    ...mapState(['me', 'loginInfo', 'api']),
    usernameValid() {
      const name = this.username;
      const m = new RegExp(RX, 'g').test(name);
      const l = name.length === 12;
      return m && l;
    },
    invalidUsername() {
      const errors = [];
      const numbersRX = new RegExp('^[a-z1-5]+$', 'g');
      const numbers = numbersRX.test(this.username);
      if (this.username.length > 0 && !numbers) {
         errors.push('- può contenere solo lettere minuscole e numeri dall\' 1 al 5.');
      }
      if (this.username.length > 12) {
        errors.push('- può contenere solo 12 caratteri.');
      }
      const firstLetter = this.username.charAt(0);
      const firstIsLetterRX = new RegExp('[a-z]');
      const firstIsLetter = firstIsLetterRX.test(firstLetter);
      if (firstLetter && !firstIsLetter) errors.push('- deve cominciare con una lettera');
      return errors;
    }
  },
}
</script>

<style lang="scss" scoped>

$border: rgba(0, 0, 0, 0.5);

.Account {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__box {
    padding: 20px;
    background-color: white;
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
  }
}

.InputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  label {
    font-size: 12px;
    margin-bottom: 3px;
    margin-left: 3px;
  }

  input {
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid $border;
  }
}
button {
  background-color: #d7c500;
  &:hover {
    background-color: darken(#d7c500, 30%);
    color: white;
  }
  transition: all 0.3s ease;
  color: #000;
  border-width: 0;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  height: 36px;
  border: 1px solid $border;
  &.bottom {
    margin-top: 20px;
  }
  &.back {
    margin-bottom: 20px;
  }
  &.secondary {
    background-color: #BBBBBB;
    &:hover {
      background-color: darken(#BBBBBB, 30%);
      color: white;
    }
  }
  &:disabled {
    background-color: #BBBBBB;
  }
}
p {
  &.instructions {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &.alert {
    font-size: 16px;
    // margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    color: red;
  }
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 5px;
}
.inputton {
  display: flex;
  flex-direction: row;
  border: 1px solid $border;
  border-radius: 5px;
  input {
    flex: auto;
    border: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button {
    margin-top: 0;
    width: fit-content;
    border: 0;
    border-left: 1px solid $border;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 10px;
  }
}
.Confirm {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  &__box {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: white;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    text-align: center;
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 10px;
    }
    &__buttons{
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
